<template>
  <b-container fluid>
    <!-- Main Page Body/Markdown -->
    <div id="main">
      <vue-markdown :source="landingMarkdown" class="text-left" />
    </div>

    <!-- Page Options -->
    <div id="options">
      <h2 class="text-left">Quick Access</h2>
      <br />
      <b-row>
        <div v-for="option in landingOptions" v-bind:key="option.key">
          <b-link
            :to="option.href"
            v-bind:class="option.disabled == true ? 'disabled' : ''"
          >
            <b-card>
              <i :id="'c-' + option.key" :class="option.icon" class="lg"></i>
              <b-card-text>{{ option.title }}</b-card-text>
            </b-card>
          </b-link>
          <b-tooltip
            v-if="option.disabled == false"
            :target="'c-' + option.key"
            triggers="hover"
            placement="bottom"
          >
            {{ option.tooltip }}
          </b-tooltip>
        </div>
      </b-row>      
    </div>
<!-- <b-button @click="test">Click</b-button> -->
    <!-- Page Tour -->
    <LandingTour />
  </b-container>
</template>
<script>
import LandingTour from "../../components/LandingTour.vue";
import VueMarkdown from "vue-markdown-render";
import store from "@/store/";

export default {
  components: {
    LandingTour,
    VueMarkdown,
  },
  data() {
    return {
      buttonOptions: [
        {
          key: "manageData",
          href: { path: "/portal/data" },
          title: "Manage Records",
          icon: "fas fa-database",
          tooltip: "View uploaded data, review matches and resolve conflicts.",
        },
        {
          key: "directory",
          href: { path: "/portal/directory" },
          title: "View Directory",
          icon: "fas fa-folder",
          tooltip: "Browse, search and download contents of MatchHub.",
        },
        {
          key: "manageUsers",
          href: { path: "/portal/users" },
          title: "Manage Users",
          icon: "fas fa-users",
          tooltip: "Invite MatchHub users and manage user permissions.",
        },
      ],
    };
  },
  computed: {
    landingMarkdown() {
      return `
Welcome to MatchHub  
============

Here you will find information about MatchHub and quick access navigation buttons for the rest of the site
`;
    },
    landingOptions() {
      var options = this.buttonOptions;
      for (var optKey in options) {
        var option = options[optKey];
        var shouldAdd = false;
        if (option.href != undefined) {
          var route = null;
          for (var rKey in this.$router.options.routes) {
            var r = this.$router.options.routes[rKey];
            if (r.path == option.href.path) {
              route = r;
              break;
            }
          }

          var permissions = route.meta.requiredPermissions;
          shouldAdd = this.$msal.hasUserPermissions(permissions);
        }

        if (shouldAdd) {
          option.disabled = false;
        } else {
          option.disabled = true;
        }
      }
      return options;
    },
  },
  methods: {
    // nothing to see here... fix it post prototype though ^
    upload() {
      this.$parent.$refs["upload-modal"].toggle();
    }
    // ,
    // test() {
    //   console.log(store.getters.userProfile);
    // }
  },
};
</script>
<style scoped>
body {
  overflow-x: hidden !important;
}

.card {
  background-color: rgba(0, 0, 255, 0.008);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  margin-right: 20px;
}
.disabled {
  color: rgba(153, 153, 153, 0.349) !important;
}
.lg {
  font-size: 400%;
  margin-top: 10px;
}
.card-text {
  margin-top: 20px;
}
.container {
  margin-top: 30px;
  margin-left: 20px;
}
a {
  color: black;
}
a:hover {
  color: #007bff;
}

#main {
  overflow: auto;
  padding-bottom: 300px;
}

#options-container {
  width: 100%;
}

#options {
  position: relative;
  bottom: 70px;
  margin-top: -250px;
  height: 250px;
  clear: both;
  padding-top: 20px;
}
</style>