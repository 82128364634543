'use strict'

import Vue from "vue";
import axios from 'axios'
import store from '@/store/index'

export let apiPluginInstance;

// Defines the functions available to the $api Vue.js variable
export class ApiPlugin
{
    constructor(vue, options)
    {
        if (!options) throw new Error('Api plugin options must be specified');
        this.pluginOptions = options;
        apiPluginInstance = this;
        Vue.prototype.$api = this;

        this.axiosInstance = axios.create({
            baseURL: this.pluginOptions.apiBaseUrl,
        });
        this.secureAxiosInstance = axios.create({
            baseURL: this.pluginOptions.secureApiBaseUrl
        });
    }

    //
    getBaseUrl()
    {
        return this.pluginOptions.apiBaseUrl;
    }

    //
    handleApiError(error)
    {
        if (error.response) console.error(error.response.data);
        throw error;
    }

    getRequestParams(params={}, secure=true)
    {
        // console.log("start getRequestParams")
        var output = params;
        if (secure) {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            if ('headers' in output) {
                output['headers']['Authorization'] = jwt;
                output['headers']['mhw-appuser'] = encodedAppUserProfile;
                output['headers']['Ocp-Apim-Subscription-Key'] = this.pluginOptions.ocpApimSubscriptionKey;
            }
            else {
                output['headers'] = {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                };
            }
        }
        // console.log("endGetRequestParams");
        return output;
    }

    getRequestParamsDELETE(params={}, secure=true)
    {
        var output = {};
        if (secure) {
            var encodedAppUserProfile = Buffer.from(JSON.stringify(store.getters.userProfile)).toString('base64');
            var jwt = store.getters.msalLogin.accessToken;
            if ('headers' in output) {
                output['headers']['Authorization'] = jwt;
                output['headers']['mhw-appuser'] = encodedAppUserProfile;
                output['headers']['Ocp-Apim-Subscription-Key'] = this.pluginOptions.ocpApimSubscriptionKey;
            }
            else {
                output['headers'] = {
                    'Authorization': jwt,
                    'mhw-appuser': encodedAppUserProfile,
                    'Ocp-Apim-Subscription-Key': this.pluginOptions.ocpApimSubscriptionKey
                };
            }          
        }
        output['data'] = params;
        return output;
    }

    //
    async performGET(url, params,secure=true)
    {
        // console.log("StartPerformGet-Params")
        try {
            if (Object.keys(params).length > 0) {
                var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                url = url + "?" + queryString;
            }            
            const resp = await this.axiosInstance.get(url, this.getRequestParams({}, secure));
            return resp.data;
        } catch (error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performPOST(url, params, secure=true)
    {
        try {
            const resp = await this.axiosInstance.post(url, this.getRequestParams(params, secure));
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performSecurePOST(url, params, secure = true)
    {
        try {
            const resp = await this.secureAxiosInstance.post(url, this.getRequestParams(params, secure));
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performDELETE(url, params, secure=true)
    {
        try {
            const resp = await this.axiosInstance.delete(url, this.getRequestParamsDELETE(params, secure));
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performPATCH(url, params, secure=true)
    {
        try {
            const resp = await this.axiosInstance.patch(url, this.getRequestParams(params, secure));
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performPUT(url, params, secure=true, config={})
    {
        try {
            const resp = await this.axiosInstance.put(url, this.getRequestParams(params, secure), config);
            return resp.data;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }

    //
    async performAzureFileUpload(url, file)
    {
        try {            
            // No need for auth or user token here, SAS handles that.
            var config = {
                headers: {
                    "x-ms-blob-type": "BlockBlob"
                }
            }
            // Upload form data
            const resp = await this.axiosInstance.put(url, file, config);
            return resp;
        } catch(error) {
            this.handleApiError(error);
            return null;
        }
    }
}

export default {
    // Called by Vue.use(ApiPlugin)
    install(vue, options) {
        new ApiPlugin(vue, options);
    }
}